import {createSlice} from "@reduxjs/toolkit";

export const verification = createSlice(
  {
    name: "verification",
    initialState: {
      title: "",
      description: "",
      showHelper: false,
      helperMessage: "",
      stepNumber: 0,
      idDocType: "",
      idFile: null,
      hideHead: false,
      utilityBill:null,
      verificationStatus: "detection", /*detection, scanning, success, failed*/
    },
    reducers: {
      setTitle: (state, action) => {
        state.title = action.payload
      },
      setDescription: (state, action) => {
        state.description = action.payload
      },
      setShowHelper: (state, action) => {
        state.showHelper = action.payload
      },
      setHelperMessage: (state, action) => {
        state.helperMessage = action.payload
      },
      setStepNumber: (state, action) => {
        state.stepNumber = action.payload
      },
      setIdDocType: (state, action) =>{
        state.idDocType = action.payload
      },
      setIdFile:(state, action) =>{
        state.idFile = action.payload
      },
      setIsVerificationStart: (state, action)=>{
        state.isVerificationStart = action.payload
      },
      setUtilityBill: (state, action) => {
        state.utilityBill = action.payload
      },
      setVerificationStatus: (state, action) => {
        state.verificationStatus = action.payload
      }
    }
  }
)

export const {
  setTitle,
  setDescription,
  setShowHelper,
  setHelperMessage,
  setStepNumber,
  setIdDocType,
  setIdFile,
  setIsVerificationStart,
  setUtilityBill,
  setVerificationStatus,
} = verification.actions

export default verification.reducer;