import {createSlice} from "@reduxjs/toolkit";

export const registration = createSlice(
    {
        name: "registration",
        initialState: {
            firstName: "",
            lastName: "",
            title: "",
            description: "",
            mail: "",
            birthday: "",
            password: "",
            doublePassword: "",
            isCheckedPolicy: false,
            showHelper: false,
            helperMessage: "",
            isAccountCreated: false,
            stepNumber: 1,
            residency: "",
            taxPayerNumber: "",
            isTaxNumberForgotten: false,
            isUsTaxResident: true,
            isInfluencer: true,
            isDataCorrect: false,
            isUserAgree: false,
            phoneNumber: "",
            objectives: "",
            experience: "",
            turnover: ""
        },
        reducers: {
            setFirstName: (state, action) => {
                state.firstName = action.payload
            },
            setLastName: (state, action) => {
                state.lastName = action.payload
            },
            setTitle: (state, action) => {
                state.title = action.payload
            },
            setDescription: (state, action) => {
                state.description = action.payload
            },
            setMail: (state, action) => {
                state.mail = action.payload
            },
            setBirthday: (state, action) =>{
              state.birthday = action.payload
            },
            setPassword: (state, action) => {
                state.password = action.payload
            },
            setDoublePassword: (state, action) => {
                state.doublePassword = action.payload
            },
            toggleCheckedPolicy: (state) => {
                state.isCheckedPolicy = !state.isCheckedPolicy
            },
            setShowHelper: (state, action) => {
                state.showHelper = action.payload
            },
            setHelperMessage: (state, action) => {
                state.helperMessage = action.payload
            },
            setIsAccountCreated: (state, action) =>{
              state.isAccountCreated = action.payload
            },
            setStepNumber: (state, action) => {
                state.stepNumber = action.payload
            },
            setResidency: (state, action) =>{
              state.residency = action.payload
            },
            setTaxPayerNumber: (state, action) => {
                state.taxPayerNumber = action.payload
            },
            setIsTaxNumberForgotten: (state, action) => {
                state.isTaxNumberForgotten = action.payload
            },
            setIsUsTaxResident: (state, action) => {
                state.isUsTaxResident = action.payload
            },
            setIsInfluencer: (state, action) => {
                state.isInfluencer = action.payload
            },
            setIsDataCorrect: (state, action) => {
                state.isDataCorrect = action.payload
            },
            setIsUserAgree: (state, action) => {
                state.isUserAgree = action.payload
            },
            setPhoneNumber: (state, action) => {
                state.phoneNumber = action.payload
            },
            setObjectives: (state, action) => {
                state.objectives = action.payload
            },
            setExperience: (state, action) => {
                state.experience = action.payload
            },
            setTurnover: (state, action) => {
                state.turnover = action.payload
            },
        }
    }
)


export const {
    setFirstName,
    setLastName,
    setTitle,
    setDescription,
    setMail,
    setBirthday,
    setPassword,
    setDoublePassword,
    toggleCheckedPolicy,
    setShowHelper,
    setHelperMessage,
    setIsAccountCreated,
    setStepNumber,
    setResidency,
    setTaxPayerNumber,
    setIsTaxNumberForgotten,
    setIsUsTaxResident,
    setIsInfluencer,
    setIsDataCorrect,
    setIsUserAgree,
    setPhoneNumber,
    setObjectives,
    setExperience,
    setTurnover,
} = registration.actions

export default registration.reducer;