import React from "react"

import Ru from "../images/svg/ru.svg"
import Gb from "../images/svg/gb.svg"
import De from "../images/svg/de.svg"

/**
 * Список языков и их характеристик
 * @module src/templates/languages
 */
export const languages = {
    ru: {
        value: "ru",
        title: "Русский",
        short_title: "Рус",
        icon: <Ru />
    },
    en: {
        value: "en-gb",
        title: "English",
        short_title: "Eng",
        icon: <Gb />
    },
    de: {
        value: "de-de",
        title: "Deutsch",
        short_title: "Deu",
        icon: <De />
    }
}