import { configureStore } from "@reduxjs/toolkit";

import langReducer from "./slices/lang.js";
import headerReduser from "./slices/header.js";
import registrationReducer from "./slices/registration";
import verificationReducer from "./slices/verification"

/**
 * Хранилище Redux
 * @module src/store/index
 */
export default configureStore({
  reducer: {
    lang: langReducer,
    header: headerReduser,
    registration: registrationReducer,
    verification: verificationReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['verification/setIdFile']
    }
  })
});
